import api from "@/base/utils/request";

//企业详情
export const companyDetail = data => {
  return api({
    url: "/admin/cyc/CompanyData/detail",
    method: "post",
    data
  });
};

// 全部企业分类
export const companyCategory = data => {
  return api({
    url: "/admin/cyc/CompanyCategory/all",
    method: "post",
    data
  });
};

// 获取企业员工列表
export const CompanyMembers = data => {
  return api({
    url: "/admin/cyc/CompanyData/members",
    method: "post",
    data
  });
};

// 保存企业信息
export const SaveCompany = data => {
  return api({
    url: "/admin/cyc/CompanyData/save",
    method: "post",
    data
  });
};