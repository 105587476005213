<!--
 * @Author: dongjia
 * @Date: 2021-08-05 13:45:19
 * @LastEditTime: 2021-09-23 17:44:32
 * @LastEditors: aleaner
 * @Description: 导入企业弹窗
 * @FilePath: \organization-admin\components\Member\ImportMembers.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="批量导入企业"
             :visible.sync="openDialog" width="540px" class="dialog-vertical"
             close-on-click-modal close-on-press-escape>
    <div class="dialog-content">
      <div class="content-title">请按以下步骤操作：</div>
        <div class="content-step">
          步骤1：下载《批量导入企业模板》，按模板中的说明进行填写。
          <el-button class="btn" type="text" style="margin-left:10px" @click="downloadExcel">下载模板</el-button>
        </div>
        <div class="content-step">步骤2：上传文件后系统会自动进行批量导入企业。</div>
    </div>
    <div slot="footer">
      <div class="footer-btn">
        <el-button size="small" @click="openDialog = false">关闭</el-button>
        <UploadFileButton style="margin-left: 20px;" :open="openDialog" :update="update" button-text="上传文件" />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import UploadFileButton from "./UploadFileButton.vue";
import {getImportCompanyTemplate} from "@/modules/mail/api/company/company-list";

export default {
  components: { UploadFileButton },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      detailForm: null,
      templateFileOne: '',
      templateFileTwo: '',
    };
  },
  methods: {
    // 下载导入会员文件模板
    downloadExcel() {
      getImportCompanyTemplate()
    },
    // 导入完成操作
    update() {
      this.openDialog = false;
      this.updateList();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-title {
  line-height: 1;
  color: #3a3a3a;
}

.dialog-content.school {
  .content-step {
    display: block;
    margin-top: $space;
  }

  .el-link {
    vertical-align: baseline;
  }
}

.content-step {
  color: #3a3a3a;
  margin-top: 20px;

  .btn {
    padding: 0;
    margin-right: 10px;
  }
}
.footer-btn {
  display: flex;
  float: right;
  padding-bottom: 20px;
  .el-button {
    margin-left: 20px;
  }
}
</style>
