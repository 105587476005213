<template>
  <el-upload class="upload-file-turn-text"
             ref="upload"
             action="#"
             :limit="limit"
             :http-request="customUpload"
             accept=".xlsx, .xls"
             :file-list="fileList"
             :show-file-list="false"
             :disabled="loading"
             :on-exceed="handleExceed">
    <template v-if="typeof buttonText === 'string'">
      <el-button :size="buttonSize" type="primary"
                 style="min-width: 100px;"
                 :loading="loading" :icon="buttonIcon">
        {{loading ? (progress ? `${progress}%` : '导入中') : buttonText}}
      </el-button>
    </template>
    <template v-else>
<!--      <el-button-group>-->
<!--        <el-button :size="buttonSize" type="primary" :loading="loading">-->
<!--          <i v-if="buttonIcon && !loading" :class="buttonIcon"></i>-->
<!--          {{loading?'导入中':buttonText[0]}}-->
<!--        </el-button>-->
<!--        <el-button :size="buttonSize" type="primary" :loading="loading">-->
<!--          <i v-if="buttonIcon && !loading" :class="buttonIcon"></i>-->
<!--          {{loading?'导入中':buttonText[1]}}-->
<!--        </el-button>-->
<!--      </el-button-group>-->
    </template>
    <template #tip>
      <div v-if="tip" class="el-upload__tip">
        <i v-if="tipIcon" :class="tipIcon"></i>
        {{tip}}
      </div>
    </template>
  </el-upload>
</template>
<script>
import {importCompanies, updateLocation} from "@/modules/mail/api/company/company-list";

import {readXlsxFileData, runPromiseByQueue, sliceArrFn} from "@/base/utils/tool";

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Function,
    },
    //按钮文字
    buttonText: {
      type: [String, Array],
      default: "点击上传",
    },
    //按钮大小
    buttonSize: {
      type: String,
      default: "small",
    },
    //按钮图标
    buttonIcon: {
      type: String,
      default: "el-icon-upload2",
    },
    //提示文字
    tip: String,
    //提示文字图标
    tipIcon: String,
    onCustomUpload: Function
  },
  watch: {
    open(val) {
      if (val) {
        this.$refs.upload.clearFiles();
        this.loading = false
        this.progress = 0
      }
    },
  },
  data() {
    return {
      limit: 1, //限制文件数量
      fileList: [], //上传的文件数组，展示用
      loading: false, //转换中
      progress: 0
    };
  },
  methods: {
    //文件限制超出
    handleExceed(file, fileList) {
      this.$message.error("最多可上传一个文件");
    },
    openFullScreen() {
      this.loadingObj = this.$loading({
        lock: true,
        text: '正在批量更新……，进度 0 %'
      });
    },
    // 自定义上传
    customUpload(uploadObj) {
      this.loading = true;

      if (this.fileList.length > 0) {
        this.fileList = [];
      }

      readXlsxFileData(uploadObj.file, (data, titles, list) => {
        this.$refs.upload.clearFiles();
        const size = 100
        const sliceArr = sliceArrFn(list, size)
        const errMsgObjArr = []
        let count = 0
        runPromiseByQueue(sliceArr.map((s, sI) => {
          return () => new Promise(resolve => {
            let handler
            if (this.onCustomUpload !== undefined) {
              handler = this.onCustomUpload
            } else {
              handler = importCompanies
            }
            handler({
              titles,
              list: s,
              // excel 里面 行号 3 才是真实数据开始
              start_row: sI * size + 3,
            }).then((res) => {
              if (res.data.status === 1) {
                /* 处理地址更新 */
                const idList = [] || res.data.data || []
                if (idList.length) {
                  // this.openFullScreen()
                  // manager = new AQM({maxParallel: 10})
                  // this.progress = 0
                  // idList.forEach(id => {
                  //   manager.push(() => {
                  //     return updateLocation({id})
                  //   })
                  // })

                  // manager.start(() => {
                  //   this.progress++
                  //   const percentage = (this.progress * 100 / idList.length).toFixed(0)
                  //   this.loadingObj.setText(`正在批量更新……，进度 ${percentage} %`)
                  // }).then(() => {
                  //   this.loadingObj.close()
                  //   this.fileList = [{ name: e.file.name }];
                  //   this.update();
                  //   this.loading = false
                  //   this.$message.success(res.msg);
                  // }).catch(() => {
                  //   this.loadingObj.close()
                  //   this.fileList = [{ name: e.file.name }];
                  //   this.loading = false
                  //   this.update();
                  // })
                } else {
                  // this.$refs.upload.clearFiles();
                  // this.$message.success(res.msg);
                  // this.fileList = [{ name: e.file.name }];
                  // this.update();
                  // this.loading = false;
                }
                errMsgObjArr.push(res.data)
              } else {
                /* 记录不同的导入失败原因 */
                if (!errMsgObjArr.find(errObj => errObj.msg === res.data.msg)) {
                  // D4 表示 excel D 列 第四行
                  errMsgObjArr.push(res.data)
                }
              }
              count++
              if (count === sliceArr.length) {
                this.progress = 100
              } else {
                this.progress = Math.floor(count * 100 / sliceArr.length)
              }
              resolve()
            }).catch((err) => {
              errMsgObjArr.push({
                msg: err || err.message || err.msg,
                status: 0
              })
              resolve()
            })
          })
        })).then(() => {
          if (errMsgObjArr.filter(e => e.status === 0).length) {
            this.$msgbox
              .confirm(errMsgObjArr.filter(e => e.status === 0).map(e => e.msg).join('；<br/>'),
                '导入失败', {
                  type: "error",
                  showCancelButton: false,
                  dangerouslyUseHTMLString: true
                })
              .then(() => {})
              .catch(() => {})
          } else {
            this.$message.success('导入成功')
          }

          this.loading = false
          this.update()
        }).catch(() => {
        })
      }, () => {
        this.$refs.upload.clearFiles();
        this.loading = false
        this.progress = 0
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-file-turn-text {
  ::v-deep .el-list-enter-active,
  ::v-deep .el-list-leave-active {
    transition: none;
  }

  ::v-deep .el-list-enter,
  ::v-deep .el-list-leave-active {
    opacity: 0;
  }
  .el-upload__tip {
    line-height: 1;
  }
  ::v-deep.el-upload-list__item {
    width: 202px;
  }
}

.el-upload-list {
  display: none;
}
ul {
  display: none;
}

  /*.el-button-group {*/
  /*  .el-button {*/
  /*    margin-left: 0*/
  /*  }*/
  /*}*/
</style>
<style>
.el-message-box__status {
  top: 12px;
}

.el-message-box__message {
  white-space: pre-wrap;
}

.el-message-box__content {
  overflow: auto;
  max-height: 70vh;
}
</style>