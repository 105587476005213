<template>
  <div>
    <div class="list-page">
      <el-button class="top-btn" type="primary" size="small" @click="handleAddCompany">新增
      </el-button>
      <el-button class="top-btn" type="primary" size="small" @click="isShowCompanyImport=true">批量导入
      </el-button>
      <list-filter :filterForm="filterForm" :selectArr="selectArr" :page="pageData.current_page"
        :uploadFilter="ok" />
      <list-tabs v-model="filterForm.tab" :isLoading="loading" :tabs="tabArr" @tab-click="handleClick">
      </list-tabs>
      <div class="list-content" v-loading="loading">
        <div class="list-btn" v-if="filterForm.tab === 'normal'">
          <el-button type="danger" size="small" @click="handleDel">删除</el-button>
        </div>
        <div class="list-btn" v-if="filterForm.tab === 'deleted'">
          <el-button type="success" size="small" @click="handleReduct">还原</el-button>
          <el-button type="danger" size="small" @click="handleDel">删除</el-button>
        </div>
        <el-table :data="tbody" class="thead-light" stripe style="width: 100%"
          @selection-change="handleSelectionChange" @sort-change="sortChange">
          <!-- 勾选-->
          <el-table-column fixed type="selection" width="55"></el-table-column>
          <!-- 操作 -->
          <el-table-column label="操作" :width="filterForm.tab === 'normal' ? '200' : '250'" fixed="right">
            <template slot-scope="scope">
              <!-- 回收站 -->
              <el-button-group v-if="filterForm.tab == 'deleted'">
                <el-button type="text" size="small" @click="deleteDetail(scope.row)">详情</el-button>
                <el-button type="text" size="small" @click="ReductRow(scope.row)">还原</el-button>
                <el-button type="text" size="small" @click="deleteRow(scope.row)">删除</el-button>
              </el-button-group>
              <!-- 全部 -->
              <el-button-group v-if="filterForm.tab == 'normal'">
                <el-button v-if="!scope.row.top_status || scope.row.top_status === 2" type="text" size="small" @click="setTop(scope.row)">置顶</el-button>
                <el-button v-else type="text" size="small" @click="cancelTop(scope.row)">取消置顶</el-button>
                <el-button type="text" size="small" @click="editRow(scope.row)">编辑</el-button>
                <el-button type="text" size="small" @click="deleteRow(scope.row)">删除</el-button>
              </el-button-group>
            </template>
          </el-table-column>
          <template v-for="(th, index) in thead">
            <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
              :min-width="th.minWidth || ''" :key="index" :sortable="th.sortable">
              <template slot-scope="scope">
                <!-- 状态 -->
                <p v-if="th.prop === 'ident_status'">
                  <span :class="[
                      'status-dot',
                      scope.row[th.prop] === 1 ? 'green' : 'yellow',
                    ]"></span>
                  {{ scope.row[th.prop] === 1 ? "已认证" : "待认证" }}
                </p>
                <!-- 举报数 -->
                <div v-else-if="th.prop === 'report_count'">
                  {{ scope.row[th.prop] }}
                  <el-button v-if="scope.row[th.prop] > 0" type="text" @click="checkReportRecode(scope.row)">查看
                  </el-button>
                </div>
                <!-- 普通 -->
                <span v-else-if="th.prop === 'is_on_stock'">
                  {{ scope.row[th.prop] === 1 ? "已上市" : "未上市" }}
                </span>
                <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    </div>
    <!-- 举报记录弹窗 -->
    <ReportRecodeDialog v-model="showReportRecodeDialog" :requestData="ReportRecodeRequest" />
    <!-- 置顶弹窗 -->
    <SetTopDialog v-model="isShow" :post_id="TopId" :updateList="getList"></SetTopDialog>
    <!-- 导入弹窗 -->
    <import-companies v-model="isShowCompanyImport" :update-list="getList" />
  </div>
</template>

<script>
import listFilter from "../../../components/Company/ListFilter";
import ReportRecodeDialog from "@/modules/common/components/ReportRecodeDialog";
import SetTopDialog from "../../../components/Company/SetTopDialog"
import Pagination from "@/base/components/Default/Pagination";
import {
  CompanyList,
  softDelete,
  Delete,
  putBack,
  cancelTop,
} from "../../../api/company/company-list";
import ImportCompanies from "@/modules/mail/components/Company/ImportCompanies";
import query from "@/base/mixins/query";

export default {
  mixins: [query],
  data() {
    return {
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "回收站", name: "deleted" },
      ],
      loading: false, //加载
      thead: [
        { label: "企业ID", prop: "id", minWidth: 90 },
        { label: "企业名称", prop: "name", minWidth: 150 },
        // {
        //   label: "企业法人",
        //   prop: "oper_name",
        //   minWidth: 110,
        // },
        {
          label: "行业类型",
          prop: "category_names",
          minWidth: 100,
        },
        // { label: "上市公司", prop: "is_on_stock", minWidth: 90 },
        // {
        //   label: "成立时间",
        //   prop: "start_date",
        //   minWidth: 120,
        //   sortable: true,
        // },
        { label: "企业地址", prop: "address", minWidth: 150 },
        // {
        //   label: "认证状态",
        //   prop: "ident_status",
        //   minWidth: 90,
        // },
        // { label: "管理员", prop: "manage_nickname", minWidth: 110 },
        {
          label: "关联会员数量",
          prop: "company_user_count",
          minWidth: 120,
          sortable: false,
        },
        {
          label: "被举报数量",
          prop: "report_count",
          minWidth: 120,
          sortable: true,
        },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 160,
          sortable: true,
        },
      ],
      //筛选对象
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        keyword: "", //企业名称
        is_on_stock: -1, //是否上市
        ident_status: -1, // 认证状态
        category_id: -1, // 企业类型
        start_date: -1, //成立时间
        tab: "normal", //当前筛选tab
        page_size: 15, //每页多少条数据
      },
      //筛选缓存
      filters: {
        normal: null,
        deleted: null,
      },
      pageData: {
        page_size: 15,
        tab: "normal",
      },
      tbody: [],
      selectArr: [], //勾选的数组id
      companyId: "", //举报记录的企业id
      showReportRecodeDialog: false, //是否显示举报记录弹窗
      ReportRecodeRequest: {
        page: 1,
        page_size: 15,
        data_type: "company", // 举报数据类型
        data_table: "cyc_company", // 举报数据表
        data_id: "", // 举报内容id
      },
      isShow: false,
      TopId: null,
      isShowCompanyImport:false,
      mixin__sessionStorageKey: 'query_company_list'
    };
  },
  methods: {
    // 查看举报记录
    checkReportRecode({ id }) {
      this.ReportRecodeRequest.data_id = id;
      this.showReportRecodeDialog = true;
    },
    // 获取品牌列表
    getList(pageData) {
      this.tbody = [];
      this.pageData = {};
      this.loading = true;
      CompanyList(pageData)
        .then((res) => {
          const { data } = res;
          // console.log(data);
          this.tbody = data.data;
          this.pageData = data;
          this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 新增企业
    handleAddCompany() {
      this.$router.push({
        name: "addViewEdit",
        params: { type: "add", id: 0 },
      });
    },
    // 设置置顶
    setTop(row) {
      this.isShow = true;
      this.TopId = row.id
    },
    // 取消置顶
    cancelTop(row) {
      this.$msgbox.confirm("确定要取消置顶吗？", "提示", {
        type: 'info'
      }).then((res) => {
        cancelTop({id: row.id})
          .then(res => {
            this.$message.success(res.msg)
            this.getList()
          })
          .catch(err => {
            console.log(err)
          })
      }).catch((err) => {})
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (this.filterForm.tab === "normal") {
          this.$msgbox
            .confirm("确定要将选中数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将选中数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        }
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (this.filterForm.tab === "normal") {
        this.$msgbox
          .confirm("确定要将此数据移入回收站吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      } else {
        this.$msgbox
          .confirm("确定要将此数据永久删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      if (this.filterForm.tab === "normal") {
        softDelete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        Delete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm("确定要将此数据还原吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.Reduct({ id: row.id });
        })
        .catch((err) => {});
    },
    // 还原数据
    Reduct(id) {
      this.loading = true;
      putBack(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击编辑
    editRow(row) {
      const routeName = "addViewEdit"
      this.mixin__beforeLeaveList(routeName)
      this.$router.push({
        name: routeName,
        params: { type: "edit", id: row.id },
      });
    },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          keyword: "", //企业名称
          is_on_stock: -1, //是否上市
          ident_status: -1, // 认证状态
          category_id: -1, // 企业类型
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        };
      }
      this.getList(this.filterForm);
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    refreshList() {
      this.getList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 分页查询
    changPage(e) {
      // console.log(e);
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getList(pageData);
    },
    // 详情
    deleteDetail(row) {
      const routeName = "addViewEdit"
      this.mixin__beforeLeaveList(routeName)
      this.$router.push({
        name: routeName,
        params: { type: "recycle", id: row.id },
      });
    },
  },
  components: {
    ImportCompanies,
    listFilter,
    Pagination,
    ReportRecodeDialog,
    SetTopDialog
  },
};
</script>

<style lang="scss" scoped>
</style>
