<template>
  <el-dialog title="设置置顶" :visible.sync="openDialog" width="500px" :show-close="true" class="dialog-vertical"
    @close="cancel('setTop')">
    <el-form size="medium" :model="setTopData" :rules="rules" ref="setTop" label-width="80px">
      <el-form-item label="置顶位置：" prop="top_position">
        <el-checkbox-group class="mLeft" v-model="setTopData.top_position">
          <el-checkbox v-for="item in topPositionArr" :label="item.value" :key="item.value">{{item.name}}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="置顶时间：" prop="top_time">
        <el-date-picker v-model="setTopData.top_time" type="daterange" value-format="timestamp"
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('setTop')" :loading="saveLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { setTop } from '../../api/company/company-list'
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    post_id: {
      type: [Number, String],
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      saveLoading: false,
      setTopData: {
        top_time: "",
        top_position: [1,2]
      },
      topPositionArr: [
        { value: 1, name: "全部分类页" },
        { value: 2, name: "所属分类页" },
      ],
      rules: {
        top_time: [
          { required: true, message: "请选择置顶时间", trigger: "change" },
        ],
        top_position: [
          { required: true, message: "请选择置顶位置", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const result = Object.assign(
            {},
            {
              id: this.post_id,
              start_time: this.setTopData.top_time[0] / 1000,
              end_time: this.setTopData.top_time[1] / 1000,
              top_position: this.setTopData.top_position
            }
          );

          this.saveLoading = true;
          setTop(result)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.$refs[formName].resetFields();
              this.openDialog = false;
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
</style>