<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">

      <div class="filter-item">
        <el-form-item label="企业名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入企业名称" autocomplete @keyup.enter.native="ok"></el-input>
        </el-form-item>
        <el-form-item label="上市公司：" prop="is_on_stock">
          <el-select v-model="is_on_stock" placeholder="请选择">
            <el-option v-for="item in stockArr" :key="item.status" :label="item.name" :value="item.status">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行业类型：" prop="category_id">
          <el-select v-model="category_id" placeholder="请选择">
            <el-option v-for="item in categoryArr" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="认证状态：" prop="start_date">
          <el-select v-model="ident_status" placeholder="请选择">
            <el-option v-for="item in identArr" :key="item.status" :label="item.name" :value="item.status">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="认证状态：" prop="ident_status">
          <el-select v-model="ident_status" placeholder="请选择">
            <el-option v-for="item in identArr" :key="item.status" :label="item.name" :value="item.status">
            </el-option>
          </el-select>
        </el-form-item> -->
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { aElementDownload } from "@/base/utils/tool";
import { companyExport } from "../../api/company/company-list";
import { companyCategory } from "../../api/company/company-detail";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    selectArr: {
      type: Array,
      default: () => [],
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      stockArr: [
        { status: 0, name: "未上市" },
        { status: 1, name: "已上市" },
      ],
      identArr: [
        { status: 0, name: "待认证" },
        { status: 1, name: "已认证" },
      ],
      categoryArr: [],
    };
  },
  methods: {
    // 获取全部企业分类
    getCompanyCategory() {
      companyCategory()
        .then((res) => {
          this.categoryArr = res.data;
        })
        .catch((err) => {});
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    // 导出
    onExport() {
      const postData = { ...this.form, ids: this.selectArr, page: this.page };
      return companyExport(postData)
        .then(([blob, fileName]) => {
          aElementDownload(blob, fileName);
        })
        .catch(() => {});
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    this.getCompanyCategory();
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    is_on_stock: {
      get() {
        return this.form.is_on_stock == -1 ? "" : this.form.is_on_stock;
      },
      set(val) {
        this.form.is_on_stock = val;
      },
    },
    ident_status: {
      get() {
        return this.form.ident_status == -1 ? "" : this.form.ident_status;
      },
      set(val) {
        this.form.ident_status = val;
      },
    },
    category_id: {
      get() {
        return this.form.category_id == -1 ? "" : this.form.category_id;
      },
      set(val) {
        this.form.category_id = val;
      },
    },
    start_date: {
      get() {
        return this.form.start_date == -1 ? "" : this.form.start_date;
      },
      set(val) {
        this.form.start_date = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
